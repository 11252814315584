import React from "react";

import Footer from "../../../component/Footer";

import IqacSideBar from "../../component/IqacSideBar";

const iqacMembers = () => {
  return (
    <div>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 primaryHeading text-center">
            Office of the International Quality Assurance Cell
          </p>
          <hr />
        </div>
        <IqacSideBar />
      </div>
      <Footer />
    </div>
  );
};

export default iqacMembers;
