import { useState } from "react";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";

import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import { LeftSideNavigationCivil } from "../../component/leftSideNavigationCivil";
import { foundation2023, foundation2024 } from "../../../../static";

function AnnualReport() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <div>
      <FacultyName name="Department of Civil Engineering" />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationCivil />
        <div className="rightSideContent mt-5">
          <ul className="list-group">
            <a className="heading5" href={foundation2024}>
              <li className="list-group-item">Foundation 2024</li>
            </a>
            <a className="heading5" href={foundation2023}>
              <li className="list-group-item">Foundation 2023</li>
            </a>
          </ul>
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationCivil />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </div>
  );
}

export default AnnualReport;
