import React from "react";

import Footer from "../../../component/Footer";
import IqacSideBar from "../../component/IqacSideBar";
import {
  IQACDirector,
  IQACAdditionalDirector,
  IQACSectionOfficer,
  IQACItOfficer,
} from "../../../static";

const iqacOffice = () => {
  return (
    <div>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 primaryHeading text-center">
            Office of the International Quality Assurance Cell
          </p>
          <hr />
          <ul className="officialsContact">
            <li>
              <div>
                <p className="heading6">Prof. Dr. Md. Ashraf Hossain </p>
                <p className="allText">Professor</p>

                <p className="allText">Faculty of Business Administration</p>
                <p className="allText">Director, IQAC</p>
              </div>

              <img
                className="img-fluid"
                src={IQACDirector}
                alt="Director, IQAC"
              />
            </li>
            <li>
              <div>
                <p className="heading6">Dr. Iqbal Mahmud </p>
                <p className="allText">Associate Professor (Chemistry)</p>
                <p className="allText">Department of EEE</p>

                <p className="allText">Faulty of Engineering and Technology</p>
                <p className="allText">Additional Director, IQAC</p>
              </div>

              <img
                className="img-fluid"
                src={IQACAdditionalDirector}
                alt="Additional Director, IQAC"
              />
            </li>
            <li>
              <div>
                <p className="heading6">Mr. Md. Asraf </p>
                <p className="allText">Executive</p>
                <p className="allText">Accounts and Finance</p>

                <p className="allText">
                  Section Officer, IQAC (Accounts Related)
                </p>
              </div>

              <img
                className="img-fluid"
                src={IQACSectionOfficer}
                alt="Section Officer, IQAC (Accounts Related)"
              />
            </li>
            <li>
              <div>
                <p className="heading6">Mr. Firoj Alam</p>
                <p className="allText">Executive (Computer Lab)</p>
                <p className="allText">Masters Program</p>

                <p className="allText">Section Officer (ICT Related) IQAC</p>
              </div>

              <img
                className="img-fluid"
                src={IQACItOfficer}
                alt="Section Officer (ICT Related) IQAC"
              />
            </li>
          </ul>
        </div>
        <IqacSideBar />
      </div>
      <Footer />
    </div>
  );
};

export default iqacOffice;
