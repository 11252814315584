import React from "react";

import Wrapper from "../../component/Wrapper";
import OtherpageSidebar from "../component/OtherpageSideBar";

function syndicate() {
  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <p className="heading2 primaryHeading text-center">Syndicate</p>
          <p className="heading6 text-center">
            (Effective period : November 2023 � October 2025)
          </p>
          <div className="comitteeMemberList d-flex">
            <table className="table-sm table-bordered table-hover allText">
              <caption>
                ** Duration : 2 years from the date of nomination**
              </caption>
              <tbody className="thead-dark">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">
                    Position as per Private University ACT 2010
                  </th>
                  <th scope="col">Name of the Members</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    <p className="allText">Chairman</p>
                    <p className="allText">(By designation)</p>
                  </td>
                  <td>
                    <p className="allText">
                      Professor Dr. Shahid Akhtar Hossain
                    </p>
                    <p className="allText">Vice Chancellor </p>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <p className="allText">Member</p>
                    <p className="allText">(By designation)</p>
                  </td>
                  <td>
                    <p className="allText">_ _ _ _ _ _ _ _ _ _ _ _ _</p>
                    <p className="allText">Pro-Vice Chancellor</p>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    <p className="allText">Member</p>
                    <p className="allText">(By designation)</p>
                  </td>
                  <td>
                    <p className="allText">Professor Md. Shamsul Huda</p>
                    <p className="allText">Treasurer</p>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    <p className="allText">Member</p>
                    <p className="allText">
                      (Nominated by the VC from Academic Council)
                    </p>
                  </td>
                  <td>
                    <p className="allText">Prof. Dr. A.K.M. Saiful Majid</p>
                    <p className="allText">Member, BOT</p>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>
                    <p className="allText">Member</p>
                    <p className="allText">
                      (One Dean, nominated by Vice Chancellor)
                    </p>
                  </td>
                  <td>
                    <p className="allText">Professor Dr. Md. Abbas Ali Khan</p>
                    <p className="allText">
                      Dean, Faculty of Business Administration
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>
                    <p className="allText">Member</p>
                    <p className="allText">
                      (One Chairperson, nominated by Vice Chancellor)
                    </p>
                  </td>
                  <td>
                    <p className="allText">Mr. Muhammad Mahfuz Hasan</p>
                    <p className="allText">Chairperson, Department of CSE</p>
                  </td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>
                    <p className="allText">Member</p>
                    <p className="allText">(Nominated by Govt.)</p>
                  </td>
                  <td>
                    <p className="allText">Mr. Md. Abdul Matin</p>
                    <p className="allText">Additional Secretary</p>
                    <p className="allText">
                      Secondary and Higher Education Division, MOE.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">8</p>
                    <p className="allText">9</p>
                    <p className="allText">10</p>
                  </td>
                  <td>
                    <p className="allText">Member</p>
                    <p className="allText">(Three members of the BOT)</p>
                  </td>
                  <td>
                    <p className="allText">Mr. Mohammed Ali Azzam</p>
                    <p className="allText">Barrister Safayet Mohammad Raju </p>
                    <p className="allText">Mr. Md. Maniruzzaman Molla</p>
                  </td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>
                    <p className="allText">Member</p>
                    <p className="allText">(Recommended by UGC)</p>
                  </td>
                  <td>
                    <p className="allText">Professor Dr. Md. Mezbah-ul-Islam</p>
                    <p className="allText">
                      Professor, Department of Information Science and Library
                      Management,
                    </p>
                    <p className="allText">University of Dhaka</p>
                  </td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>
                    <p className="allText">Member Secretary</p>
                    <p className="allText">(By designation)</p>
                  </td>
                  <td>
                    <p className="allText">Dr. Abul Basher Khan</p>
                    <p className="allText">Registrar</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <OtherpageSidebar />
      </div>
    </Wrapper>
  );
}

export default syndicate;
