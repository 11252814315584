import React from "react";
import IqacSideBar from "../../../component/IqacSideBar";
import { IQACDirector, IQACOrganogram } from "../../../../static";

const iqacHomeContent = () => {
  return (
    <div className="content">
      <div className="OtherPagecontent">
        <hr />
        <p className="heading2 primaryHeading text-center">
          Welcome to Institutional Quality Assurance Cell (IQAC)
        </p>
        <hr />
        <div className="history">
          <div className="messageContent text-center">
            <img
              className="img-fluid messagePic"
              src={IQACDirector}
              alt="IQAC Director sir  here"
            ></img>
            <div className="placeHolder"></div>
          </div>
          <p className="heading6 text-center">
            Message from the Director IQAC of Eastern University
          </p>

          <p className="allText">
            The Institutional Quality Assurance Cell (IQAC) has been established
            by Eastern University in July of 2015. The Cell got a boost with a
            grant from the HEQEP program of the University Grants Commission.
            The authorities of Eastern University have expressed total
            commitment to the project which has the ultimate goal of developing
            a quality culture within the University. Articulation of this goal
            is quite consistent with the prevalent policy of Eastern University
            stating that when it comes to quality in teaching, we will make no
            compromise.
          </p>
          <p className="allText">
            Quality Assurance is not just about teaching, rather it is about a
            culture within the university that strives to improve quality at all
            aspects, at all levels, of the university programs, processes, and
            objectives. We see this as a continuous process of improving quality
            with no finish line.
          </p>
          <p className="allText">
            At this point, IQAC of Eastern University is focused on
            self-assessment of the Faculty of Business Administration and the
            Faculty of Arts and Social Sciences. The Faculty of Laws and Faculty
            of Engineering will soon undertake self-assessment. In the mean
            time, IQAC of Eastern University has invited several international
            scholars and organized several seminars and workshops to train the
            faculty members on developing effective, student friendly, mission
            consistent course descriptions, on conducting interactive classes,
            teaching through cases, etc. Further, several seminars were
            dedicated toward developing research skills of the faculty members.
          </p>
          <p className="allText">
            We have developed a complete year-long program of seminars and
            workshops and we invite you to visit our website frequently to get
            the latest updates. Drop by at the IQAC office at House # 3, Road #
            3 office if you have any questions. Hope to see you there.
          </p>
          <p className="heading6 text-center">
            Organogram of IQAC of Eastern University
          </p>
          <img
            className="img-fluid"
            src={IQACOrganogram}
            alt="IQAC Organogram Comes Here"
          ></img>
        </div>
      </div>
      <IqacSideBar />
    </div>
  );
};

export default iqacHomeContent;
