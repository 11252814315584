import React from "react";
import OtherpageSideBar from "../../component/OtherpageSideBar";

const OtherCommitteesContent = () => {
  return (
    <div className="content">
      <div className="OtherPagecontent">
        <hr />
        <p className="heading2 text-center">List of Statutory Committees</p>
        <p className="heading5 text-center">
          As per Private University ACT 2010
        </p>
        <hr />
        <div id="otherCommittee">
          <p className="heading4 text-center">
            <u>Finance Committee</u>
          </p>
          <p className="heading5 text-center">
            (Effective period : March 2023 – February 2026)
          </p>
          <div className="table-responsive">
            <table className="table table-hover">
              <caption>
                <p className="allText">
                  ** Duration : 3 year from the date of nomination**
                </p>
              </caption>
              <tr>
                <th>
                  <p className="allText">Sl.</p>
                </th>
                <th>
                  <p className="allText">
                    Position as per Private University Act 2010
                  </p>
                </th>
                <th>
                  <p className="allText">Name of the Members</p>
                </th>
              </tr>

              <tbody>
                <tr>
                  <td>
                    <p className="allText">1.</p>
                  </td>
                  <td>
                    <p className="allText">Chairman</p>
                    <p className="allText">
                      (One member from the Board of Trustees)
                    </p>
                  </td>
                  <td>
                    <p className="allText">Mr. Md. Ismail Zabihullah</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">2.</p>
                  </td>
                  <td rowSpan="2">
                    <p className="allText">Member</p>
                    <p className="allText">(Two other members of the BOT)</p>
                  </td>
                  <td>
                    <p className="allText">Mr. Anwar Hossain Chowdhury</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">3.</p>
                  </td>

                  <td>
                    <p className="allText">Mr. S.M. Bakhtiar Alam</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">4.</p>
                  </td>
                  <td>
                    <p className="allText">Member</p>
                    <p className="allText">(By designation)</p>
                  </td>
                  <td>
                    <p className="allText">
                      Professor Dr. Shahid Akhtar Hossain
                    </p>
                    <p className="allText">Vice Chancellor</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">5.</p>
                  </td>
                  <td>
                    <p className="allText">Member</p>
                    <p className="allText">(By designation)</p>
                  </td>
                  <td>
                    <p className="allText">Professor Md. Shamsul Huda</p>
                    <p className="allText">Treasurer</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">6.</p>
                  </td>
                  <td>
                    <p className="allText">Member</p>
                    <p className="allText">
                      (One Departmental Head recommended by the VC)
                    </p>
                  </td>
                  <td>
                    <p className="allText">Mr. Syed Habib Anwar Pasha</p>
                    <p className="allText">
                      Chairperson, Dept.of Business Administration
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">7.</p>
                  </td>
                  <td rowSpan="2">
                    <p className="allText">Member</p>
                    <p className="allText">
                      (Two specialists recommended by the Syndicate)
                    </p>
                  </td>
                  <td>
                    <p className="allText">Mr. Abul Khair Chowdhury</p>
                    <p className="allText">Member, BOT</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">8.</p>
                  </td>

                  <td>
                    <p className="allText">Dr. Mahfuzul Hoque</p>
                    <p className="allText">Professor,</p>
                    <p className="allText">
                      Department of Accounting & Information Systems,
                    </p>
                    <p className="allText">
                      Faculty of Business Studies, University of Dhaka
                    </p>
                    <p className="allText"></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">9.</p>
                  </td>
                  <td rowSpan="2">
                    <p className="allText">Member Secretary</p>
                    <p className="allText">(By designation)</p>
                  </td>
                  <td>
                    <p className="allText">Mr. Abdul Karim</p>
                    <p className="allText">
                      Deputy Director, Finance & Accounts
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p className="heading4 text-center">Teacher Selection Committee</p>
          <p className="heading5 text-center">
            (Effective period : November 2023 – October 2025)
          </p>
          <div className="table-responsive">
            <table className="table table-hover">
              <caption>
                <p className="allText">
                  **Duration : 2 years from the date of nomination**
                </p>
              </caption>
              <tr>
                <th>
                  <td>
                    <p className="allText">Sl. No</p>
                  </td>
                  <td>
                    <p className="allText">
                      Position as per Private University ACT 2010
                    </p>
                  </td>
                  <td>
                    <p className="allText">Name of the Members</p>
                  </td>
                </th>
              </tr>
              <tr>
                <tbody>
                  <tr>
                    <td>
                      <p className="allText">1.</p>
                    </td>
                    <td>
                      <p className="allText">Chairman</p>
                      <p className="allText">(By designation)</p>
                    </td>
                    <td>
                      <p className="allText">
                        Professor Dr. Shahid Akhtar Hossain
                      </p>
                      <p className="allText">Vice Chancellor</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">2.</p>
                    </td>
                    <td rowSpan="3">
                      <p className="allText">Member</p>
                      <p className="allText">(Three members of the BOT)</p>
                    </td>
                    <td>
                      <p className="allText">Mr. Abul Quasem Haider</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">3.</p>
                    </td>

                    <td>
                      <p className="allText">Mr. Md. Azizul Islam</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">4.</p>
                    </td>
                    <td>
                      <p className="allText">Mr. Md. Ismail Zabihullah</p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <p className="allText">Business Administration</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">5.</p>
                    </td>
                    <td rowspan="2">
                      <p className="allText">Member</p>
                      <p className="allText">
                        (Two specialists of the relevant subject recommended by
                        the Syndicate)
                      </p>
                    </td>
                    <td>
                      <p className="allText">Prof. Dr. A.K.M. Saiful Majid</p>
                      <p className="allText">Former Director, IBA</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">6.</p>
                    </td>
                    <td>
                      <p className="allText">Prof. Dr. ABM Shahidul Islam</p>
                      <p className="allText">
                        Chairman, Dept. of Marketing, DU
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <p className="allText">Law</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">7.</p>
                    </td>
                    <td rowSpan="2">
                      <p className="allText">Member</p>
                      <p className="allText">
                        (Two specialists of the relevant
                      </p>
                      <p className="allText">
                        subject recommended by the Syndicate)
                      </p>
                    </td>
                    <td>
                      <p className="allText">Dr. Md. Rahmat Ullah</p>
                      <p className="allText">
                        Professor, Dept. of Law, University of Dhaka
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">8.</p>
                    </td>
                    <td>
                      <p className="allText">Professor Dr. Shima Zaman</p>
                      <p className="allText">
                        Dean (Acting), Faculty of Law, University of Dhaka
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <p className="allText">English</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">9.</p>
                    </td>
                    <td rowSpan="2">
                      <p className="allText">Member</p>
                      <p className="allText">
                        (Two specialists of the relevant
                      </p>
                      <p className="allText">
                        subject recommended by the Syndicate)
                      </p>
                    </td>
                    <td>
                      <p className="allText">
                        Prof. Dr. Serajul Islam Chowdhury
                      </p>
                      <p className="allText">
                        Professor Emeritus, University of Dhaka
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">10.</p>
                    </td>
                    <td>
                      <p className="allText">Professor Tahmina Ahmed</p>
                      <p className="allText">
                        Professor, Department of English
                      </p>
                      <p className="allText">University of Dhaka</p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <p className="allText">Computer Science & Engineering</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">11.</p>
                    </td>
                    <td rowSpan="2">
                      <p className="allText">Member</p>
                      <p className="allText">
                        (Two specialists of the relevant
                      </p>
                      <p className="allText">
                        subject recommended by the Syndicate)
                      </p>
                    </td>
                    <td>
                      <p className="allText">Dr. Md. Kaykobad</p>
                      <p className="allText">Distinguished Professor</p>
                      <p className="allText">
                        Department of CSE, BRAC University
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">12.</p>
                    </td>
                    <td>
                      {/* <p className="allText">Dr. Md. Mahbubul Alam Joarder</p>
                      <p className="allText">
                        Pro Vice-Chancellor, Bangabandhu Sheikh Mujibur Rahman
                        Digital University, Bangladesh and Professor, Institute
                        of Information Technology, University of Dhaka
                      </p> */}
                      -----------------
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <p className="allText">
                        Electrical and Electronic Engineering
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">13.</p>
                    </td>
                    <td rowSpan="2">
                      <p className="allText">Member</p>
                      <p className="allText">
                        (Two specialists of the relevant
                      </p>
                      <p className="allText">
                        subject recommended by the Syndicate)
                      </p>
                    </td>
                    <td>
                      <p className="allText">Dr. Md. Aynal Haque</p>
                      <p className="allText">
                        Professor, Department of EEE, BUET
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">14.</p>
                    </td>
                    <td>
                      <p className="allText">Engr. Kh. Mesbah Uddin Ahmed</p>
                      <p className="allText">
                        Managing Director, AQUA Consultant &amp; Associates Ltd.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <p className="allText">Civil</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">15.</p>
                    </td>
                    <td rowSpan="2">
                      <p className="allText">Member</p>
                      <p className="allText">
                        (Two specialists of the relevant
                      </p>
                      <p className="allText">
                        subject recommended by the Syndicate)
                      </p>
                    </td>
                    <td>
                      <p className="allText">Dr. Mohammad Shariful Islam</p>
                      <p className="allText">Professor</p>
                      <p className="allText">
                        Dept. of Civil Engineering, BUET
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">16.</p>
                    </td>
                    <td>
                      <p className="allText">Dr. A.F.M. Saiful Amin</p>
                      <p className="allText">
                        Professor Dept. of Civil Engineering, BUET
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="3">
                      <p className="allText">Pharmacy</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">17.</p>
                    </td>
                    <td rowSpan="2">
                      <p className="allText">Member</p>
                      <p className="allText">
                        (Two specialists of the relevant
                      </p>
                      <p className="allText">
                        subject recommended by the Syndicate)
                      </p>
                    </td>
                    <td>
                      <p className="allText">Dr. Sitesh Chandra Bachar</p>
                      <p className="allText">Dean</p>
                      <p className="allText">
                        Faculty of Pharmacy, University of Dhaka
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">18.</p>
                    </td>
                    <td>
                      <p className="allText">Dr. Firoj Ahmed</p>
                      <p className="allText">Professor & Chairman</p>
                      <p className="allText">
                        Department of Pharmacy, University of Dhaka
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p className="allText">19.</p>
                    </td>
                    <td rowSpan="4">
                      <p className="allText">Member</p>
                      <p className="allText">(Concerned Dean by designation)</p>
                    </td>
                    <td>
                      <p className="allText">
                        Professor Dr. Md. Abbas Ali khan
                      </p>
                      <p className="allText">
                        Dean, Faculty of Business Administration
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">20.</p>
                    </td>
                    <td>
                      <p className="allText">Prof. Dr. Md. Mahfuzur Rahman</p>
                      <p className="allText">Dean, Faculty of E&T</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">21.</p>
                    </td>
                    <td>
                      <p className="allText">Mohd. Moniruzzaman Akhand</p>
                      <p className="allText">Dean, Faculty of Arts</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">22.</p>
                    </td>
                    <td>
                      <p className="allText">Mr. A.B.M.Imdadul Haque Khan</p>
                      <p className="allText">Dean, Faculty of Law</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">23.</p>
                    </td>
                    <td rowSpan="2">
                      <p className="allText">Member</p>
                      <p className="allText">(Concerned Departmental Head,</p>
                      <p className="allText">Professor Rank, by designation)</p>
                    </td>
                    <td>
                      <p className="allText">Prof. Dr. Mirza Golam Rabbani</p>
                      <p className="allText">Chairperson, Dept. Of EEE</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">24.</p>
                    </td>
                    <td>
                      <p className="allText">
                        Prof. Dr. Sharif Moniruzzaman Shirazi, CEng
                      </p>
                      <p className="allText">
                        Chairperson, Dept. of Civil Engineering
                      </p>
                    </td>
                  </tr>
                </tbody>
              </tr>
            </table>
          </div>

          <p className="heading4 text-center">
            <u>Disciplinary Committee</u>
          </p>
          <p className="heading5 text-center">
            (Effective period : November 2023 – October 2025)
          </p>
          <div className="table-responsive">
            <table className="table table-hover">
              <caption>
                <p className="allText">
                  **Duration : 2 years from the date of nomination**
                </p>
              </caption>
              <tr>
                <th>
                  <p className="allText">Sl</p>
                </th>
                <th>
                  <p className="allText">
                    Position as per Private University Act 2010
                  </p>
                </th>
                <th>
                  <p className="allText">Name of the Members</p>
                </th>
              </tr>
              <tr>
                <td>
                  <p className="allText">1.</p>
                </td>
                <td>
                  <p className="allText">Chairman</p>
                  <p className="allText">
                    (One member from the Board of Trustees)
                  </p>
                </td>
                <td>
                  <p className="allText">Mr. Mohammed Ali Azzam</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">2.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                  <p className="allText">(By designation)</p>
                </td>
                <td>
                  <p className="allText">Professor Dr. Shahid Akhtar Hossain</p>
                  <p className="allText">Vice Chancellor</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">3.</p>
                </td>
                <td rowSpan="4">
                  <p className="allText">Member</p>
                  <p className="allText">(All Deans, by designation)</p>
                </td>
                <td>
                  <p className="allText">Professor Dr. Md. Abbas Ali khan</p>
                  <p className="allText">
                    Dean, Faculty of Business Administration
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">4.</p>
                </td>
                <td>
                  <p className="allText">Prof. Dr. Md. Mahfuzur Rahman</p>
                  <p className="allText">Dean, Faculty of E&T</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">5.</p>
                </td>
                <td>
                  <p className="allText">Mohd. Moniruzzaman Akhand</p>
                  <p className="allText">Dean (In-Charge), Faculty of Arts</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">6.</p>
                </td>
                <td>
                  <p className="allText">Mr. A.B.M.Imdadul Haque Khan</p>
                  <p className="allText">Dean (In-Charge), Faculty of Law</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">7.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                  <p className="allText">
                    (One Departmental Head recommended by the Syndicate)
                  </p>
                </td>
                <td>
                  <p className="allText">Prof. Dr. Mirza Golam Rabbani</p>
                  <p className="allText">Chairperson, Dept. of EEE</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">8.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                  <p className="allText">(By designation)</p>
                </td>
                <td>
                  <p className="allText">Dr. Abul Basher Khan</p>
                  <p className="allText">Registrar</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">9.</p>
                </td>
                <td>
                  <p className="allText">Member Secretary</p>
                  <p className="allText">(By designation)</p>
                </td>
                <td>
                  <p className="allText">Mr. Mohd. Moniruzzaman Akhand</p>
                  <p className="allText">Proctor</p>
                </td>
              </tr>
            </table>
          </div>

          <p className="heading4 text-center">
            <u>Officials and Staff Selection Committee</u>
          </p>
          <p className="heading5 text-center">
            (Effective period : September 2021 – August 2023)
          </p>
          <div className="table-responsive">
            <table className="table table-hover">
              <caption>
                <p className="allText">
                  **Duration : 2 years from the date of nomination**
                </p>
              </caption>
              <tr>
                <th>
                  <p className="allText">Sl</p>
                </th>
                <th>
                  <p className="allText">
                    Position as per Private University Act 2010
                  </p>
                </th>
                <th>
                  <p className="allText">Name of the Members</p>
                </th>
              </tr>
              <tr>
                <td>
                  <p className="allText">1.</p>
                </td>
                <td>
                  <p className="allText">Chairman</p>
                  <p className="allText">(By designation)</p>
                </td>
                <td>
                  <p className="allText">Professor Dr. Shahid Akhtar Hossain</p>
                  <p className="allText">Vice Chancellor</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">2.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                  <p className="allText">(By designation)</p>
                </td>
                <td>
                  <p className="allText">-------------------------------</p>
                  <p className="allText">Pro- Vice Chancellor</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">3.</p>
                </td>
                <td rowSpan="3">
                  <p className="allText">Member</p>
                  <p className="allText">
                    (Three members of the Board of Trustees)
                  </p>
                </td>
                <td>
                  <p className="allText">Mr. Abul Khair Chowdhury</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">4.</p>
                </td>
                <td>
                  <p className="allText">Mr. Liaquat Hossain Moghul</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">5.</p>
                </td>
                <td>
                  <p className="allText">Mr. Mohammed Ali Azzam</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">6.</p>
                </td>
                <td rowSpan="2">
                  <p className="allText">Member</p>
                  <p className="allText">
                    (Two representatives nominated by the
                  </p>
                  <p className="allText">
                    Syndicate other than the member of BOT)
                  </p>
                </td>
                <td>
                  <p className="allText">Prof. Dr. Mohammed Abdul Aziz</p>
                  <p className="allText">Former Dean, Faculty of Science</p>
                  <p className="allText">University of Dhaka</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">7.</p>
                </td>
                <td>
                  <p className="allText">Dr. Abul Basher Khan</p>
                  <p className="allText">Registrar</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">8.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                  <p className="allText">
                    (An external Professor nominated by Vice Chancellor)
                  </p>
                </td>
                <td>
                  <p className="allText">Dr. Anisur Rahman</p>
                  <p className="allText">Professor, Department of Marketing</p>
                  <p className="allText">
                    Faculty of Business Studies University of Dhaka
                  </p>
                </td>
              </tr>
            </table>
          </div>

          <p className="heading4 text-center">
            Other Committees of Eastern University
          </p>
          <p className="heading4 text-center">
            <u>Audit Committee</u>
          </p>
          <p className="heading5 text-center">
            (Effective period : November 2023 – October 2025)
          </p>
          <div className="table-responsive">
            <table className="table table-hover">
              <caption>
                <p className="allText">**Duration : 2 years</p>
              </caption>
              <tr>
                <th>
                  <p className="allText">Sl.</p>
                </th>
                <th>
                  <p className="allText">Position</p>
                </th>
                <th>
                  <p className="allText">Name of the Members</p>
                </th>
              </tr>
              <tr>
                <td>
                  <p className="allText">01.</p>
                </td>
                <td>
                  <p className="allText">Chairman</p>
                </td>
                <td>
                  <p className="allText">Mr. Afzalur Rahman </p>
                  <p className="allText">Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">02.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. S.M. Bakhtiar Alam</p>
                  <p className="allText">Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">03.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Dr. Md. Samsuddoha Khondaker</p>
                  <p className="allText">Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">04.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. Mohammad Enayet Ullah </p>
                  <p className="allText">Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">05.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. SK. Saydur Rahman</p>
                  <p className="allText">Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">06</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. Md. Ismail Zabihullah</p>
                  <p className="allText">Member, BOT</p>
                </td>
              </tr>
            </table>
          </div>
          <p className="heading4 text-center">
            <u>Procurement Committee</u>
          </p>
          <p className="heading5 text-center">
            (Effective period : September 2021 – August 2023)
          </p>
          <div className="table-responsive">
            <table className="table table-hover">
              <caption>
                <p className="allText">**Duration : 2 years .</p>
              </caption>
              <tr>
                <th>
                  <p className="allText">Sl.</p>
                </th>
                <th>
                  <p className="allText">Position</p>
                </th>
                <th>
                  <p className="allText">Name of the Members</p>
                </th>
              </tr>
              <tr>
                <td>
                  <p className="allText">01.</p>
                </td>
                <td>
                  <p className="allText">Chairman</p>
                </td>
                <td>
                  <p className="allText">Mr. Anwar Hossain Chowdhury</p>
                  <p className="allText">Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">02.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. Abul Quasem Haider</p>
                  <p className="allText">Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">03.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. S.M. Bakhtiar Alam</p>
                  <p className="allText">Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">04.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Dr. Md. Samsuddoha Khondaker</p>
                  <p className="allText">Member, BOT</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p className="allText">05.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. Mohammad Enayet Ullah</p>
                  <p className="allText">Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">06.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. Md. Azizul Islam</p>
                  <p className="allText">Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">07.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. Liaquat Hossain Moghul</p>
                  <p className="allText">Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">08.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. SK. Saydur Rahman</p>
                  <p className="allText">Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">09.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Barrister Safayet Mohammad Raju </p>
                  <p className="allText">Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">10.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. Md. Tanvir Mahmud </p>
                  <p className="allText">Member, BOT</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p className="allText">11.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Professor Dr. Shahid Akhtar Hossain</p>
                  <p className="allText">Vice Chancellor</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">12.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Professor Md. Shamsul Huda</p>
                  <p className="allText">Treasurer</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">13.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Dr. Abul Basher Khan</p>
                  <p className="allText">Registrar</p>
                </td>
              </tr>
            </table>
          </div>
          <p className="heading4 text-center">
            <u>Admission Committee</u>
          </p>
          <p className="heading5 text-center">
            (Effective period : November 2023 – October 2025)
          </p>
          <div className="table-responsive">
            <table className="table table-hover">
              <caption>
                <p className="allText">**Duration : 2 years .</p>
              </caption>
              <tr>
                <th>
                  <p className="allText">Sl.</p>
                </th>
                <th>
                  <p className="allText">Position</p>
                </th>
                <th>
                  <p className="allText">Name of the Members</p>
                </th>
              </tr>
              <tr>
                <td>
                  <p className="allText">01.</p>
                </td>
                <td>
                  <p className="allText">Chairman</p>
                </td>
                <td>
                  <p className="allText">Professor Dr. Shahid Akhtar Hossain</p>
                  <p className="allText">Vice Chancellor</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">02.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. Abul Quasem Haider, Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">03.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">
                    Prof. Dr. ABM Shahidul Islam, Member, BOT
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">04.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">
                    Mr. Anwar Hossain Chowdhury , Member, BOT
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">05.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">
                    Dr. Md. Samsuddoha Khondaker, Member, BOT
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">06.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">
                    Barrister Safayet Mohammad Raju, Member, BOT
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">07.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. Afzalur Rahman, Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">08.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. Md. Tanvir Mahmud, Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">09.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">
                    Mr. Md. Maniruzzaman Molla, Member, BOT
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">10.</p>
                </td>
                <td>
                  <p className="allText">Invited expert member</p>
                </td>
                <td>
                  <p className="allText">Mr. Mohammed Ali Azzam, Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">11.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Professor Dr. Md. Abbas Ali khan</p>
                  <p className="allText">
                    Dean, Faculty of Business Administration
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">12.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">
                    Prof. Dr. Md. Mahfuzur Rahman, Dean, Faculty of E&T
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">13.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">
                    Mohd. Moniruzzaman Akhand, Dean, Faculty of Arts
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">14.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">
                    Mr. A.B.M.Imdadul Haque Khan, Dean , Faculty of Law
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">15.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Prof. Dr. Mirza Golam Rabbani</p>
                  <p className="allText">Chairperson, Dept. of EEE</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">16.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">
                    Prof. Dr. Sharif Moniruzzaman Shirazi, CEng
                  </p>
                  <p className="allText">
                    Chairperson, Department of Civil Engineering
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">17.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. Muhammad Mahfuz Hasan</p>
                  <p className="allText">Chairperson, Dept.of CSE</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p className="allText">18.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. Syed Habib Anwar Pasha</p>
                  <p className="allText">
                    Chairperson, Dept.of Business Administration
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">19.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Dr. Abu Bin Ihsan</p>
                  <p className="allText">Chairperson, Department of Pharmacy</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">20.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Ms. Rowshon Ara</p>
                  <p className="allText">Chairperson, Dept. of English</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">21.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. Mohammad Mamunur Rashid</p>
                  <p className="allText">Chairperson, Department of Law</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">22.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Ms. Tasnuva Rahman</p>
                  <p className="allText">Director, MBA & EMBA Program</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">23.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Dr. Abul Basher Khan </p>
                  <p className="allText">Registrar</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">24.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">
                    Ms. Nazla Fatmi, Director, Admission
                  </p>
                </td>
              </tr>

              <tr>
                <td>
                  <p className="allText">25.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">
                    Mr. Md. Farid Hossain, Deputy Registrar
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <p className="heading4 text-center">
            <u>Planning and Development Committee</u>
          </p>
          <p className="heading5 text-center">
            (Effective period : November 2023 – October 2025)
          </p>
          <div className="table-responsive">
            <table className="table table-hover">
              <caption>
                <p className="allText">**Duration : 2 years .</p>
              </caption>
              <tr>
                <th>
                  <p className="allText">Sl.</p>
                </th>
                <th>
                  <p className="allText">Position</p>
                </th>
                <th>
                  <p className="allText">Name of the Members</p>
                </th>
              </tr>
              <tr>
                <td>
                  <p className="allText">01.</p>
                </td>
                <td>
                  <p className="allText">Chairman</p>
                </td>
                <td>
                  <p className="allText">
                    Engr. Kh. Mesbah Uddin Ahmed, Member, BOT
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">02.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">
                    Mr. Mohammad Enayet Ullah,member, BOT
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">03.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. Md. Azizul Islam, Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">04.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">
                    Mr. Liaquat Hossain Moghul, Member, BOT
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">05.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. Sk. Sayedur Rahman, Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">06.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. Md. Azizul Islam, Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">07.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Mr. Md. Tanvir Mahmud, Member, BOT</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="allText">08.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">
                    Mr. Md. Maniruzzaman Molla, Member, BOT
                  </p>
                </td>
              </tr>

              <tr>
                <td>
                  <p className="allText">09.</p>
                </td>
                <td>
                  <p className="allText">Member</p>
                </td>
                <td>
                  <p className="allText">Professor Dr. Shahid Akhtar Hossain</p>
                  <p className="allText">Vice Chancellor</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <OtherpageSideBar />
    </div>
  );
};

export default OtherCommitteesContent;
